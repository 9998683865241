<template>
  <div>
    <div class="relative mt-2 bg-white" style="height: 600px" v-if="!reqLoaded">
      <loading :active="true" :is-full-page="false" />
    </div>
    <!-- {{ data.financial_accounts[0] }} -->
    <template v-if="reqLoaded">
      <PaymentMethodScreen
        :data="data"
        :currencySymbol="currencySymbol"
        :fullName="getFullName"
        @addBalance="handleAdjustBalancePopup('add')"
        @deductBalance="handleAdjustBalancePopup('deduct')"
      />

      <AdjustBalancePopup
        :data="popupData"
        :wallets="getWallets"
        :currencySymbol="currencySymbol"
        @adjustedBalance="handleBalanceAdjustment($event)"
      />
    </template>
  </div>
</template>

<script>
import { EventBus } from '@/utils/EventBus'

export default {
  name: 'ViewRiderUserPayments',
  components: {
    PaymentMethodScreen: () =>
      import('@/composites/rider/payment-method/PaymentMethodScreen.vue'),
    AdjustBalancePopup: () =>
      import('@/composites/rider/payment-method/blocks/AdjustBalancePopup.vue'),
  },
  data: () => ({
    reqLoading: false,
    reqLoaded: false,
    reqFailed: false,
    reqFailures: null,

    data: {
      default: '',
      wallet_data: {
        total_debit: 0,
        total_credit: 0,
        current_balance: 0,
        total_trips: 0,
      },
      card_data: {
        total_amount_to_wallet: 0,
        total_amount_spend: 0,
        total_trips: 0,
        cards: [],
      },
      pass_data: null,
    },
    popupData: {
      type: 'add',
      userId: '',
      fullName: '',
      mobileNumber: '',
      currentBalance: 0.0,
    },
  }),
  computed: {
    id() {
      return this.$route.params.id
    },
    currencySymbol() {
      return this.$org?.default_currency?.symbol || '$'
    },
    riderInfo() {
      return this.$store.getters['riderdetails/getData']
    },
    getFullName() {
      return this?.riderInfo?.full_name || '--'
    },
    getPhoneNumber() {
      return this?.riderInfo?.phone_number || '--'
    },
    getWallets() {
      const wallets = this?.data?.financial_accounts
      return (
        wallets?.map((wallet, i) => {
          return {
            id: wallet.id,
            text: `Wallet ${i + 1} / ${wallet.currency.symbol}${
              wallet.balance
            } ${wallet.default ? '(Default)' : ''}`,
            balance: wallet.balance,
            currency: wallet.currency,
            default: wallet.default,
          }
        }) || []
      )
    },
  },
  async created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.reqLoading = true
      const endpoint = this.$config.riderUser.api.paymentInfo(this.id)
      await this.$http
        .get(endpoint)
        .then((res) => {
          this.data = res.data
          this.reqLoaded = true
          console.warn(res.data)
        })
        .catch((err) => {
          this.reqFailures = err
          this.reqFailed = true
        })
        .finally(() => {
          this.reqLoading = false
        })
    },
    handleAdjustBalancePopup(type) {
      if (
        ['Owner', 'Agent'].includes(
          this.$store.getters['auth/roleInfo'].role_name
        )
      ) {
        this.popupData = {
          type,
          userId: this.id,
          fullName: this.getFullName,
          phoneNumber: this.getPhoneNumber,
          currentBalance: Number(this.data.wallet_data.current_balance),
        }

        this.$modal.show('AdjustBalancePopup')
        EventBus.$emit(this.$config.riderUser.events.adjustBalancePop)
      } else {
        this.$notify(
          {
            group: 'generic',
            type: 'error',
            title: 'Permission Error',
            text:
              'Without Owner or Agent, anyone cannot perform this operation',
          },
          5000
        )
      }
    },
    async handleBalanceAdjustment() {
      await this.fetchData()
      // this.data.wallet_data.current_balance = e
      // this.$store.dispatch('riderdetails/saveBalance', e)
    },
  },
}
</script>

<style lang="scss"></style>
